<template>
    <div class="messageBg _pc-pad-x-350 _mb-pad-x-15" v-if="show">
        <div class="_w-max _white-bg _pc-pad-x-30 _pc-pad-y-30" style="border-radius:8px;">
            <div class="_flex-row-bet-mid _pc-mar-bottom-15 _mb-pad-y-30">
                <div class="_pc-font-25 _b _mb-font-50">{{ $t('订单留言') }}</div>
                <img @click="close" class="_pc-w-35 _mb-w-100" style="cursor: pointer;" src="@/assets/image/hangda/close-icon.png" alt="">
            </div>
            <div class=" _pc-mar-bottom-15 _flex-row-mid _mb-display-none" >
                <div class="_pc-pad-x-15 _pc-pad-y-15 _flex-1" style="border-radius:8px;background-color:#F7F7F7;">
                    <textarea v-model="content" class="_pc-h-60 _w-max _pc-font-18 _mb-h-80 _mb-font-40" :placeholder="$t('请输入内容')" style="background-color:#F7F7F7;"></textarea>
                </div>
                <div @click="send" class="_pc-w-120 _pc-pad-x-10 _pc-pad-y-15 theme-color-bg _white _flex-row-center-mid _pc-mar-left-20 _mb-w-200 _mb-pad-y-30" style="border-radius:6px;cursor: pointer;">
                    <div>{{ $t('send') }}</div>
                </div>
            </div>
			<div class=" _pc-mar-bottom-15 _flex-col-mid _pc-display-none">
			    <div class="_pc-pad-x-15 _pc-pad-y-15 _flex-1 _w-max" style="border-radius:8px;background-color:#F7F7F7;">
			        <textarea v-model="content" class="_pc-h-60 _w-max _pc-font-18 _mb-h-200 _mb-font-35 _mb-pad-y-10 _mb-pad-x-10" :placeholder="$t('请输入内容')" style="background-color:#F7F7F7;"></textarea>
			    </div>
			    <div @click="send" class="_w-max _pc-pad-x-10 _pc-pad-y-15 theme-color-bg _white _flex-row-center-mid _pc-mar-left-20 _mb-pad-y-20 _mb-mar-top-20" style="border-radius:6px;cursor: pointer;">
			        <div>{{ $t('send') }}</div>
			    </div>
			</div>
            <div class="_w-max _over-hid" style="border-radius:8px;border:1px solid #F5F5F5;">
                <div class="_w-max _pc-font-18 _b _pc-pad-y-15 _pc-pad-x-15 _mb-font-50" style="background:#F5F5F5;">{{ $t('聊天记录') }}</div>
                <div class="_pc-mar-bottom-10" style="height:55vh;overflow-y:scroll;">
                    <div class="_w-max _border-bottom _pc-pad-y-15 _pc-pad-x-15 _flex-row _mb-pad-y-20" v-for="(item,index) in list" :key="index">
                        <img class="_border-circle _pc-w-60 _pc-mar-right-20" v-if="item.message_type == 1" style="width:60px;height:60px;" :src="item.avatar" alt="">
                        <img class="_border-circle _pc-w-60 _pc-mar-right-20" v-if="item.message_type == 2" style="width:60px;height:60px;" :src="item.agent_logo" alt="">
                        <div class="_flex-1 _pc-w-120 _mb-mar-left-15">
                            <div class="_pc-font-16 _b _pc-mar-bottom-10 _flex-row-bet-mid _mb-font-30">
                                <div v-if="item.message_type == 1">{{item.nickname}}</div>
                                <div v-if="item.message_type == 2">{{item.agent_name}}</div>
                                <div class="_pc-font-14 _grey-99 _mb-font-20">{{item.add_time}}</div>
                            </div>
                            <div class="_pc-font-16 _mb-font-40">{{item.message_content}}</div>
                        </div>
                    </div>
                </div>
                <div class="_w-max _flex-row-mid" style="justify-content:flex-end;">
                    <Page :total="count" :page-size="thisFrom.limit" @on-change="pageChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get_order_msg_list, add_order_message } from '@/api/jiyun'
export default {
    name: 'orderMessage',
    data() {
        return {
            show:false,
            id:0,
            content:'',
            list:[],
            count:0,
            thisFrom:{
                limit:5,
                page:1,
            },
            order_id: 0,
        };
    },
    watch:{
        show:{
            handler(){
                if(!this.show){
                    this.list = [];
                    this.content = '';
                    this.id = 0;
                    this.order_id = 0;
                    this.thisFrom = {
                        limit:5,
                        page:1,
                    };
                }
            },
        },
    },

    methods: {
        //获取聊天记录
        getMessage(id){
            this.thisFrom.order_id = id;
            get_order_msg_list(this.thisFrom).then((res)=>{
                this.show = true;
                this.list = res.data.list;
                this.count = res.data.count;
                this.order_id = id;
            }).catch((err)=>{
                this.$Message.error(err.msg);
            })
        },
        //发送聊天记录
        send(){
            if(!this.content) return this.$Message.error(this.$t('请输入发送内容'));
            let param = {
                order_id: this.order_id,
                content: this.content
            }
            add_order_message(param).then((res)=>{
                this.$Message.success(res.msg);
                this.content = '';
                this.getMessage(this.order_id);
            }).catch((err)=>{
                this.$Message.error(err.msg)
            })
        },
        //关闭弹窗
        close(){
            this.show = false;
            this.$emit('onlound');
        },
        // 分页
        pageChange(index) {
            this.thisFrom.page = index
            this.getMessage(this.order_id)
        },
    },
};
</script>

<style scoped>
.messageBg{
    background: rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
textarea{border:unset;outline-style: none;}

.theme-color-bg{
    background: rgb(66,132,220);
}
</style>