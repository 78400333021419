<template>
    <div class="_pos-rel"  style="background-color:rgba(247, 247, 247, 1);">
        <div class="z-index-2" style="position:sticky;top:7.99087vh;z-index:2;">
            <div class="_flex-row-center-mid _pos-rel _mb-pad-y-25 _white _mb-font-32" style="background-color:rgba(66, 132, 220, 1);">
                <router-link :to="'/user_mobile'" class="_pos-abs _left _mb-pad-left-25 _flex-row-mid">
                    <img class="_mb-w-20 _mb-mar-right-10" src="~@/assets/image/hangda/fanhui1.png" alt="">
                    <div class="_mb-font-28">{{$t('返回')}}</div>
                </router-link>
                <div style="line-height:1;">{{$t('我的订单')}}</div>
            </div>
            <div class="_white-bg">
                <div class="_mb-pad-x-30 _mb-pad-y-25">
                    <div class="_mb-pad-x-20 _mb-pad-y-20 _mb-font-28 _flex-row-mid" style="background-color:rgba(245, 245, 245, 1);border-radius:5px;">
                        <img class="_mb-w-30 _mb-mar-right-20" src="@/assets/image/hangda/sousuo.png" alt="">
                        <input v-model="keywords" style="background-color:rgba(245, 245, 245, 1);" class="_flex-1" type="text" :placeholder="$t('搜索订单关键词')">
                        <div @click="search" class="_mb-pad-left-20 _mb-mar-left-20 _border-left" style="color:rgba(66, 132, 220, 1);">{{$t('查询')}}</div>
                    </div>
                </div>
                <div class="_w-max _mb-pad-x-30 _flex-row" style="overflow-x:scroll;">
                    <div @click="changeStats(0)" class="_mb-h-55 _flex-col-mid _mb-font-28 _pos-rel _mb-mar-right-40">
                        <div class="_line-1" :class="thisFrom.status== 0?'_b choose-color':''" >{{$t('全部')}}</div>
                        <div v-if="thisFrom.status== 0" class="_mb-w-25 _pos-abs _bottom" style="height:2px;background-color:rgba(66, 132, 220, 1);border-radius: 2px 2px 0px 0px;"></div>
                    </div>
                    <div @click="changeStats(item.status)" v-for="(item,index) in statusList" :key="index" class="_mb-h-55 _flex-col-mid _mb-font-28 _pos-rel " :class="index+1 != statusList.length?'_mb-mar-right-40':''">
                        <div class="_line-1" :class="thisFrom.status== item.status?'_b choose-color':''" >{{item.name}}</div>
                        <div v-if="thisFrom.status==item.status" class="_mb-w-25 _pos-abs _bottom" style="height:2px;background-color:rgba(66, 132, 220, 1);border-radius: 2px 2px 0px 0px;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="_mb-pad-x-30 _mb-pad-y-25">
            <div @click="toDetail(item.id)" v-for="(item,index) in list" :key="index" class="_w-max _mb-mar-bottom-20" style="border:1px solid rgba(217, 217, 217, 1);">
                <div class="_w-max _mb-pad-x-30 _mb-pad-y-25 _mb-font-28 _border-bottom" style="background-color:rgba(245, 245, 245, 1);">{{$t('订单号')}}：{{item.ordersn}}</div>
                <div class="_white-bg _w-max _mb-pad-x-30 _mb-pad-y-25 _mb-font-28">
                    <div class="_flex-row-mid _mb-mar-bottom-15">
                        <div class="_mb-w-150 ">{{$t('状态')}}</div>
                        <div class="_b" :style="{color:`${item.status_color}`}">{{item.status_name}}</div>
                    </div>
                    <div class="_flex-row _mb-mar-bottom-15">
                        <div class="_mb-w-150">{{$t('路线')}}</div>
                        <div class="_flex-row-mid _flex-wrap" style="color:rgba(66, 132, 220, 1);">
                            <span v-if="lang == 'zh_cn'" v-for="(items,indexs) in item.express_name" :key="indexs">{{items}}、</span>
                            <span v-if="lang == 'ru'" v-for="(items,indexs) in item.express_name_ru" :key="indexs">{{items}}、</span>
                            <span v-if="lang == 'en_us'" v-for="(items,indexs) in item.express_name_us" :key="indexs">{{items}}、</span>
                        </div>
                    </div>
                    <div class="_flex-row-mid _mb-mar-bottom-15">
                        <div class="_mb-w-150">{{$t('总重量')}}</div>
                        <div  style="color:rgba(66, 132, 220, 1);" v-if="item.is_type === 1">{{item.big_weight||'---'}}kg({{$t('common')}}{{item.report_num}}{{$t('package')}})</div>
                        <div  style="color:rgba(66, 132, 220, 1);" v-else>{{item.weight||'---'}}kg({{$t('common')}}{{item.report_num}}{{$t('package')}})</div>
                    </div>
                    <div class="_grey-99 _mb-font-24">{{$t('创建时间')}}：{{item.add_time}}</div>
                    <div class="_w-max _flex-row-mid ">
                        <div class="_mb-w-150" style="flex-shrink: 0;">{{ $t('打包单号') }}：</div>
                        <div class="_flex-1" style="display: flex;align-items: center;column-gap: 1rem;flex-wrap: wrap;gap: 6px;">
                            <div class="pack-num" v-for="(items,index) in item.out_package_list" :key="index">{{ items.pack_no }}</div>
                        </div>
                    </div>
                    <div @click.stop="toCheck()" class="_w-max _flex-row-center-mid _white _mb-font-28 _mb-h-75 _mb-mar-top-30" style="border-radius:6px;background-color:rgba(66, 132, 220, 1);">{{$t('查询物流')}}</div>
                    <div @click.stop="getMessage(item.id)" class="_w-max _flex-row-center-mid _white _mb-font-28 _mb-h-75 _mb-mar-top-30" style="border-radius:6px;background-color:rgba(66, 132, 220, 1);">{{ $t('user_center.message') }}</div>
                </div>
            </div>
        </div>
        <div v-if="list.length == 0" class="_mb-font-36 _b _mb-mar-y-45 _text-center">~{{$t('暂无数据')}}~</div>
        <div class="_w-max _mb-pad-x-30 _mb-pad-y-15">
            <Page :total="count"  show-total @on-change="pageChange" :page-size="thisFrom.limit"/>
        </div>
	    <Message ref="orderMessage" @onlound='onlound'></Message>
    </div>
</template>

<script>
import {userOrderList} from '@/api/jiyun.js'
import Message from './components/message';
export default {
    name: 'MobileOrderlist',
    components: {
        Message
    },
    data() {
        return {
            list:[],
            count:0,
            thisFrom:{
                limit:15,
                page:1,
                status:'0',
                keywords:'',
                domain_url: window.location.origin
            },
            keywords:'',
            statusList:[
                {
                    status:80,
                    name:this.$t('已打包')
                },
                {
                    status:85,
                    name:this.$t('已合包')
                },
                {
                    status:190,
                    name:this.$t('已发货')
                },
                {
                    status:100,
                    name:this.$t('国外签收')
                },
                {
                    status:110,
                    name:this.$t('国外上架')
                },
                {
                    status:120,
                    name:this.$t('已完成')
                },
            ],
            checked:false,
            lang: '',
        };
    },
    mounted() {
        this.lang = localStorage.getItem('think-lang') || 'ru';
        if(this.$route.query.status){
            this.thisFrom.status = this.$route.query.status;
        }else{
            this.getList();
        }
    },
    watch:{
        'thisFrom.status':{
            handler(){
                this.thisFrom.page = 1;
                this.list = [];
                this.getList();
            },
        },
        keywords:{
            handler(){
                if(this.keywords == ''){
                    this.thisFrom.keywords = '';
                }
            },
        },
    },
    methods: {
        search(){
            if(this.checked){return}
            this.thisFrom.keywords = this.keywords;
            this.thisFrom.page == 1;
            this.list = [];
            this.getList();
        },
        getList(){
            this.checked = true;
            userOrderList(this.thisFrom).then(res=>{
                this.list = res.data.list;
                this.count = res.data.count;
                this.checked = false;
            }).catch(res=>{
                this.checked = false;
            });
        },
        //关闭留言弹窗重新获取订单列表
        onlound(){
            this.getList();
        },
        //打开留言
        getMessage(id){
            this.$refs.orderMessage.getMessage(id);
        },
        // 分页
        pageChange(index) {
            if(this.checked){return}
            this.thisFrom.page = index
            this.getList()
        },
        toDetail(id){
            this.$router.push({ 
                name: 'mobile_orderDetail',
                query: {id}
            })
        },
        toCheck(express_sn){
            if(this.checked){return}
            this.$router.push({
                path: '/search',
                query:{
                    expresssn:express_sn,
                }
            })
        },
        changeStats(status){
            if(this.thisFrom.status == status){return}
            if(this.checked){return}
            this.thisFrom.status = status;
        },
    },
};
</script>

<style  scoped>
.choose-color{
    color: rgba(66, 132, 220, 1);
}
.pack-num {
    background: #4284DC;color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
}
</style>